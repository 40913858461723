import * as React from 'react';
import { Provider } from 'react-redux';
import { SymbolsTable } from '@wt-nx/wt-client/frontend-common-data/symbols/_components/symbols-table/symbols-table';
import { getSymbolsTableClasses } from '@wt-nx/wt-client/frontend-common-data/symbols/_components/symbols-table/symbols-table.classes';
import { store } from '@wt-nx/wt-client/globals';
import { autobind } from '@wt-nx/wt-client/utils';
import { Block, block, Dimensions, Element, ISwitchCondition } from '@wt-nx/wt-client/utils';
import { changeSymbolsFilterThunk } from '@wt-nx/wt-client/frontend-common-data/symbols/_thunks/symbols-filter-change.thunk';
import * as fltrCtrl from '@wt-nx/wt-client/frontend-common-data/controls/Filter/Filter';
import type { ISymbolsStoreSegment } from '@wt-nx/symbols/client/domain';
import { Symbol } from '@wt-nx/symbols/client/domain';
import { BySubstringFilter } from '@wt-nx/wt-client/frontend-common-data/models/Filtering/Filter';
import { ChatButton } from '@wt-nx/wt-client/frontend-common-data/components/ChatButton/ChatButton';
import { SymbolsTab } from '@wt-nx/wt-client/frontend-common-data/components/SymbolsTableWrapper/SymbolsTab';
import { IconType } from '@wt-nx/components/domain';
import { connect } from 'react-redux';
import type { IScreenStoreSegment } from '@wt-nx/navigation/client/domain';
import { Screen } from '@wt-nx/navigation/client/domain';
import { openPositionScreenThunk } from '@wt-nx/wt-client/frontend-common-data/trading/open-position-screen.thunk';
import { t } from '@wt-nx/wt-client/i18n/domain';
import { createPathSelector } from 'reselect-utils';
import { symbolsSegmentSelector } from '@wt-nx/symbols/client/domain';
import { WithThemeProviderWrapper } from '@wt-nx/wt-client/frontend-common-data/utils/react';
import { symbolsTableWrapperPropsSelector } from './symbols-table-wrapper.props-selector';
import type { IFakeModeStoreSegment } from '@wt-nx/auth/client/domain';
import { fakeModeSegmentSelector } from '@wt-nx/auth/client/domain';
import { DataTestIds } from '@wt-nx/autotests';
import { createRoot, Root } from 'react-dom/client';

const symbolsName = 'symbols';
const symbolsBlock = block(symbolsName);
const headerClass = symbolsBlock.elem('header');
const tabsClass = symbolsBlock.elem('tabs');
const tabClass = symbolsBlock.elem('tab');

const classes = getSymbolsTableClasses();

interface ISymbolsTableHeaderProps {
  headerRef: (h: HTMLElement) => void;
  isVirtual?: boolean;
}

class SymbolsTableHeaderComponent extends React.Component<ISymbolsTableHeaderProps> {
  render() {
    const { headerRef, isVirtual } = this.props;
    return (
      <div className={headerClass} ref={headerRef}>
        {isVirtual ? null : (
          <div className={tabsClass}>
            <SymbolsTab
              id={'all-symbols-tab'}
              label={t('$Symbol_Symbols')}
              className={tabClass}
              icon={IconType.List}
              dispatchWhenVisible={true}
              testEntity={{ id: DataTestIds.Symbols.AllSymbolsTab }}
            />
            <SymbolsTab
              id={'favorites-tab'}
              label={t('$Symbol_Favorites')}
              className={tabClass}
              icon={IconType.Favorite}
              dispatchWhenVisible={false}
              testEntity={{ id: DataTestIds.Symbols.FavoritesTab }}
            />
          </div>
        )}
        <div className={classes.thead}>
          <div className={classes.favorite} />
          <div className={classes.symbol}>{t('$Symbol_Symbol')}</div>
          <div className={classes.quote}>{t('$Symbol_Bid')}</div>
          <div className={classes.quote}>{t('$Symbol_Ask')}</div>
          <div className={classes.spread}>{t('$Symbol_Spread')}</div>
        </div>
      </div>
    );
  }
}

const SymbolsTableHeader = connect(symbolsTableWrapperPropsSelector)(SymbolsTableHeaderComponent);

interface ISymbolsTableProps {
  isVirtual?: boolean;
  screen?: Screen;
  action: (symbol?: string) => void;
}

class SymbolsTableInnerComponent extends React.Component<ISymbolsTableProps> {
  @autobind
  private extractId(child: any) {
    const symbolCell = child.children[1] as HTMLElement;
    return symbolCell?.children[0]?.id;
  }

  render() {
    const { isVirtual, action, screen } = this.props;
    return <SymbolsTable hideFavorites={isVirtual} action={action} screen={screen} extractId={this.extractId} />;
  }
}

interface ISymbolsTableWrapperProps {
  action?: (symbol?: string) => void;
}

const SymbolsTableWrapperInnerComponent = connect<void, void, ISymbolsTableWrapperProps>(
  (state: ISymbolsStoreSegment & IScreenStoreSegment & IFakeModeStoreSegment, props) => {
    const isVirtual = createPathSelector(fakeModeSegmentSelector).isVirtualMode()(state);
    const areFavoritesVisible = createPathSelector(symbolsSegmentSelector).areFavoritesVisible()(state);
    return {
      ...props,
      isVirtual,
      screen: areFavoritesVisible ? Screen.Favorites : Screen.AllSymbols,
    };
  },
)(SymbolsTableInnerComponent);

export class SymbolBySubstringFilter extends BySubstringFilter<Symbol> {
  public qualifies(symbol: Symbol) {
    return symbol.name.toLowerCase().indexOf(this.getSearchValue()) > -1;
  }
}

export class SymbolsTableWrapper extends Block {
  private _root: Root;
  private _root2: Root;
  private _tableElement: Element;
  private _headerElement: Element;
  private _header: HTMLElement;

  constructor(symbolsVisibility: ISwitchCondition) {
    super(symbolsName);
    this.nodeId = symbolsName;

    const content = this.createElem('content');
    const filter = new SymbolBySubstringFilter();
    filter.onupdated.on((data) => {
      store.dispatch(changeSymbolsFilterThunk(data['search_str']));
    });
    const filterCtrl = new fltrCtrl.SubstringFilter<Symbol>(filter, t('$Symbol_Symbol'));
    content.appendAsElem(filterCtrl, 'filters');
    this.appendElem(content);

    this._headerElement = this.createElem('header');
    content.appendElem(this._headerElement);

    this._tableElement = this.createElem('table');
    content.appendElem(this._tableElement);
    this.appendAsElem(new ChatButton('SymbolsTable', 'chat-btn'), 'chat-btn');

    if (symbolsVisibility.calcSwitchState()) {
      this.showComponents();
    }
    symbolsVisibility.switchConditionEvents.forEach((event) =>
      this.subscribe(event, () => {
        const showHide = symbolsVisibility.calcSwitchState();
        if (showHide) {
          this.showComponents();
        } else {
          this.hideComponents();
        }
      }),
    );
  }

  private onSymbolItemClick(symbol?: string): void {
    symbol && store.dispatch(openPositionScreenThunk({ symbol }));
  }

  private showComponents() {
    this._root = this._root || createRoot(this._headerElement.html);
    this._root.render(
      <Provider store={store}>
        <WithThemeProviderWrapper>
          <SymbolsTableHeader
            headerRef={(h) => {
              this._header = h;
            }}
          />
        </WithThemeProviderWrapper>
      </Provider>,
    );

    this._root2 = this._root2 || createRoot(this._tableElement.html);
    this._root2.render(
      <Provider store={store}>
        <WithThemeProviderWrapper>
          <SymbolsTableWrapperInnerComponent action={this.onSymbolItemClick} />
        </WithThemeProviderWrapper>
      </Provider>,
    );
  }

  private hideComponents() {
    this._root = this._root || createRoot(this._headerElement.html);
    this._root.render(null);

    this._root2 = this._root2 || createRoot(this._tableElement.html);
    this._root2.render(null);
  }

  public setDimensions(dims: Dimensions) {
    super.setDimensions(dims);
    this.update();
  }

  public update() {
    if (this._header) {
      let headerHeight = 0;
      const headerStyle = getComputedStyle(this._header);

      if (headerStyle) {
        headerHeight = parseInt(headerStyle.height, 10);
      }
      if (headerHeight !== 0) {
        this._tableElement.setDimensions({ height: this.dimensions.height - headerHeight });
      }
    }
  }

  protected freeThis() {
    this.hideComponents();
    super.freeThis();
  }
}
