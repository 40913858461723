import typescriptFsa from 'typescript-fsa';
import type { IStocksItemStore } from './stocks.interface';
import type { ITopGroupItemStore } from '@wt-nx/wt-client/frontend-common-data/rating/rating.interface';
import type { IStocksGrowthUpdateEventResDto } from '@wt-nx/stocks/cs';
import type { IFailedPayload } from '@wt-nx/wt-client/common';

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace StocksAction {
  const actionCreator = typescriptFsa('Stocks');

  export const GrowthUpdateEvent = actionCreator<IStocksGrowthUpdateEventResDto>('GrowthUpdateEvent');
  export type IStocksTopLoadDonePayload = ITopGroupItemStore<IStocksItemStore>;

  export const TopGainersLoad = actionCreator.async<void, IStocksTopLoadDonePayload, IFailedPayload>('TopGainersLoad');

  export const TopLosersLoad = actionCreator.async<void, IStocksTopLoadDonePayload, IFailedPayload>('TopLosersLoad');

  export const PopularLoad = actionCreator.async<void, IStocksTopLoadDonePayload, IFailedPayload>('PopularLoad');
}
