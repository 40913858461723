import { Dispatch } from 'redux';
import { AnyAction } from 'typescript-fsa';
import { SymbolsActionOld } from '@wt-nx/symbols/client/domain';

export function changeSymbolsFilterThunk(filter: string) {
  return (dispatch: Dispatch<AnyAction>) => {
    dispatch({
      type: SymbolsActionOld.SymbolsFilterUpdate,
      payload: filter,
    });
  };
}
