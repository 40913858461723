import type { IScreenStoreSegment } from '@wt-nx/navigation/client/domain';
import {
  DEFAULT_AUTHENTICATED_SCREEN,
  navigateBackWithParams,
  navigationRef,
  Screen,
  screenSegmentSelector,
} from '@wt-nx/navigation/client/domain';
import { ExceptionReporter } from '@wt-nx/logger/client/domain';
import type { INotificationsStoreSegment } from '@wt-nx/notification/client/domain';
import { notificationAddThunk, NotificationType } from '@wt-nx/notification/client/domain';
import { t } from '@wt-nx/wt-client/i18n/domain';
import { createPathSelector } from 'reselect-utils';
import type { IActiveAccountStoreSegment, IChangeAccountStoreSegment } from '@wt-nx/account/client/domain';
import {
  accountsSegmentSelector,
  activeAccountSegmentSelector,
  ChangeAccountAction,
  changeAccountSegmentSelector,
  getActiveAccountData,
} from '@wt-nx/account/client/domain';
import type { IActiveUserStoreSegment, ILastActiveUserStoreSegment } from '@wt-nx/user/client/domain';
import { activeUserSegmentSelector, tokenSelector } from '@wt-nx/user/client/domain';
import type { IThunk } from '@wt-nx/wt-client/utils';
import type { IBrandStoreSegment } from '@wt-nx/brand/client/domain';
import type { IChangeAccountState } from '@wt-nx/auth/client/feature';
import { changeAccount } from './change-account';

interface IChangeAccountByIdThunkProps {
  accountId: string;
  screen?: Screen;
}

export type IChangeAccountByIdState = IChangeAccountStoreSegment &
  IActiveUserStoreSegment &
  IActiveAccountStoreSegment &
  IScreenStoreSegment &
  ILastActiveUserStoreSegment &
  INotificationsStoreSegment &
  IBrandStoreSegment &
  IChangeAccountState;

export const changeAccountByIdThunk: IThunk<IChangeAccountByIdThunkProps, IChangeAccountByIdState> = ({
  accountId,
  screen,
}) => {
  return (dispatch, getState) => {
    const state = getState();
    const isRequested = createPathSelector(changeAccountSegmentSelector).requested()(state);
    if (isRequested) {
      return;
    }
    dispatch(ChangeAccountAction.Requested());
    const id = createPathSelector(activeUserSegmentSelector).id()(state);
    const accounts = accountsSegmentSelector(state);
    const token = tokenSelector(state);

    const account = accounts.get(accountId);
    if (!account) {
      ExceptionReporter.send('Not a crash. Account.tsx changeAccount to non existing', {
        accountId,
        accounts: accounts.map((a) => ({ account: a.accountNumber, server: a.serverId })),
      });
    } else {
      const accountId = createPathSelector(activeAccountSegmentSelector).accountNumber()(state);
      if (accountId === account.accountNumber) {
        const currentScreen = createPathSelector(screenSegmentSelector).currentScreen()(state);
        if ([Screen.AccountsListBottomNav, Screen.AccountsListLeftMenu].includes(currentScreen)) {
          navigateBackWithParams({ screenToSkip: Screen.SideMenu });
        }
      } else if (account.isServerUnavailable) {
        dispatch(
          notificationAddThunk({
            type: NotificationType.Warning,
            message: t('$Acc_ServerUnavailable'),
          }),
        );
      } else {
        getActiveAccountData(id, account, token, accounts.toArray())
          .then((data) => {
            return changeAccount(data, account, dispatch, getState).then(() => {
              navigationRef.navigate(screen || DEFAULT_AUTHENTICATED_SCREEN);
            });
          })
          .catch((_err: any) => {
            dispatch(
              notificationAddThunk({
                message: t('$Notification_TradingServerUnavailable'),
                type: NotificationType.Warning,
              }),
            );
            dispatch(ChangeAccountAction.AccountChangeError());
          });
        return;
      }
    }
    dispatch(ChangeAccountAction.AccountChangeError());
  };
};
