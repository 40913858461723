import * as React from 'react';
import { connect } from 'react-redux';
import { autobind } from '@wt-nx/wt-client/utils';
import { ItemsTable } from '@wt-nx/wt-client/components/base';
import { getSymbolsTableClasses } from './symbols-table.classes';
import { SymbolsTableItem } from '../symbols-table-item/symbols-table-item';
import { SymbolsTableGroup } from '../symbols-table-group/symbols-table-group';
import type { ISymbolTableItem } from '@wt-nx/symbols/client/domain';
import type { ISymbolsTableOwnProps, ISymbolsTableProps, ISymbolsTableStateProps } from './symbols-table.interface';
import { symbolsTablePropsSelector } from './symbols-table.props-selector';

const { tableName } = getSymbolsTableClasses();

class SymbolsTableComponent extends React.PureComponent<ISymbolsTableProps> {
  @autobind
  renderSymbolsTableItem({ isGroupHeader, ticket }: ISymbolTableItem) {
    const { action, hideFavorites, screen } = this.props;
    return isGroupHeader ? (
      <SymbolsTableGroup groupName={ticket} />
    ) : (
      <SymbolsTableItem symbolName={ticket} action={action} hideFavorites={hideFavorites} screenName={screen} />
    );
  }

  render() {
    const { symbolsTableItems, collapsedInstruments, extractId, onLayout } = this.props;

    return (
      <ItemsTable
        id="SymbolsTable"
        tableName="symbols-table"
        items={symbolsTableItems}
        className={tableName}
        extractId={extractId}
        renderRow={this.renderSymbolsTableItem}
        observableItems={collapsedInstruments}
        initialNumToRender={20}
        onLayout={onLayout}
      />
    );
  }
}

export const SymbolsTable: React.FC<ISymbolsTableOwnProps> = connect<ISymbolsTableStateProps>(
  symbolsTablePropsSelector,
  {},
)(SymbolsTableComponent);
