import * as React from 'react';
import { connect } from 'react-redux';
import { toggleAreFavoritesVisibleThunk } from '@wt-nx/wt-client/frontend-common-data/symbols/_thunks/change-are-favorites-visible.thunk.desktop';
import { autobind } from '@wt-nx/wt-client/utils';
import { Tab } from '@wt-nx/wt-client/frontend-common-data/controls/Tab/Tab';
import type {
  ISymbolsTabDispatchProps,
  ISymbolsTabOwnProps,
  ISymbolsTabProps,
  ISymbolsTabStateProps,
} from './symbols-tab.interface';
import { symbolsTabPropsSelector } from './symbols-tab.props-selector';

export class SymbolsTabComponent extends React.PureComponent<ISymbolsTabProps> {
  @autobind
  onClick() {
    const { areFavoritesVisible, dispatchWhenVisible, toggleFavoriteSymbols } = this.props;
    if ((areFavoritesVisible && dispatchWhenVisible) || (!areFavoritesVisible && !dispatchWhenVisible)) {
      toggleFavoriteSymbols();
    }
  }

  render() {
    const { areFavoritesVisible, dispatchWhenVisible } = this.props;
    const isActive = (areFavoritesVisible && !dispatchWhenVisible) || (!areFavoritesVisible && dispatchWhenVisible);
    return (
      <Tab
        {...this.props}
        active={isActive}
        onClick={this.onClick}
        testEntity={{ ...this.props.testEntity, data: { 'is-selected': isActive } }}
      />
    );
  }
}

export const SymbolsTab: React.FC<ISymbolsTabOwnProps> = connect<ISymbolsTabStateProps, ISymbolsTabDispatchProps>(
  symbolsTabPropsSelector,
  {
    toggleFavoriteSymbols: toggleAreFavoritesVisibleThunk,
  },
)(SymbolsTabComponent);
