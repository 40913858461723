import * as React from 'react';
import { Div, Txt } from '@wt-nx/wt-client/components/base';
import { useClasses } from '@wt-nx/wt-client/theme';
import { withClasses } from './confirmation-description.css';
import type { IConfirmationDescriptionProps } from './confirmation-description.interface';

export const ConfirmationDescription: React.FC<IConfirmationDescriptionProps> = (props) => {
  const classes = useClasses(withClasses);
  const { text } = props;

  return (
    <Div css={classes.container}>
      <Txt css={classes.text} value={text} />
    </Div>
  );
};
