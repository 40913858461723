import * as React from 'react';
import { useState } from 'react';
import { t } from '@wt-nx/wt-client/i18n/domain';
import type { INavigationScreenProps } from '@wt-nx/navigation/client/domain';
import { Screen } from '@wt-nx/navigation/client/domain';
import { ConfirmationScreen } from '../../confirmation-screen';
import { CopyFxSubscriptionConfirmationContents } from './subscription-confirmation-contents';

const CTRL_NAME = 'CopyFxSubscriptionConfirmation';

type ICopyFxSubscriptionConfirmationProps = INavigationScreenProps<Screen.CopyFxSubscriptionConfirmation>;

export const CopyFxSubscriptionConfirmation: React.FC<ICopyFxSubscriptionConfirmationProps> = (props) => {
  const [isAgreeChecked, setIsAgreeChecked] = useState(false);

  const onAgreeChanged = (checked: boolean) => {
    setIsAgreeChecked(checked);
  };

  const { params } = props.route;

  return (
    <ConfirmationScreen
      {...props}
      id={CTRL_NAME}
      header={t('$CopyFx_Subscribe')}
      title={params.title.toLocaleUpperCase()}
      confirmLabel={t('$Confirm')}
      disabled={!isAgreeChecked}
      onConfirm={params.onConfirm}
      content={
        <CopyFxSubscriptionConfirmationContents
          {...params}
          onAgreeChanged={onAgreeChanged}
          isAgreeChecked={isAgreeChecked}
        />
      }
    />
  );
};
